<header>
    <div class="top-header">
        <div class="container-fluid ">
            <div class="top-menu">
                <div class="lft-logo">
                    <a href="https://drome.co.in/" target="_blank"><img src="../../assets/images/beta_logo.png" style="width:auto;height:60px" class="logo"></a>
                </div>
                <div class="all-signup-links">
                    <a (click)="redairect()" style="display: flex;justify-self: center;align-items: center;background: #44D7B6;cursor: pointer;color: #000;">
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-house-fill" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="m8 3.293 6 6V13.5a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 13.5V9.293l6-6zm5-.793V6l-2-2V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5z"/>
                                <path fill-rule="evenodd" d="M7.293 1.5a1 1 0 0 1 1.414 0l6.647 6.646a.5.5 0 0 1-.708.708L8 2.207 1.354 8.854a.5.5 0 1 1-.708-.708L7.293 1.5z"/>
                            </svg>
                        </span>
                        <span>Home</span> 
                    </a>
                    <a (click)="LoginRedairect()" style="display: flex;justify-self: center;align-items: center;background: #44D7B6;cursor: pointer;color: #000;">
                        <span>Login</span> 
                    </a>
                    <a (click)="SignupRedairect()" *ngIf="router.url == '/login'"style="display: flex;justify-self: center;align-items: center;background: #44D7B6;cursor: pointer;color: #000;">
                        <span>Sign Up</span> 
                    </a>
                    <!-- <a routerLink="/cc/signup">Clinic Coordinator Signup</a> -->
                    <!-- <a routerLink="/ad/signup">Admin Signup</a>
                    <a routerLink="/sad/signup">Super Admin Signup</a> -->
                    <!-- <a routerLink="/ndd/signup">Non Drome Doctor Sign Up</a> -->
                    <!-- <a routerLink="/ccnd/signup">Non Drome Clinic Coordinator Signup</a> -->
                </div>
            </div>
        </div>
    </div>
</header>
<!--login form section start-->
<section class="main_box">
    <div class="backgrountimage" *ngIf="pageName=='login'||pageName=='memberegister'||pageName=='doctorregister'||pageName=='ndd'||pageName=='ayushPractioners'||pageName=='ad'"></div>
    <router-outlet></router-outlet>
</section>
<div class="footer">
    <div class="f-left">
        <!-- <a href="https://drome.co.in/"><img [src]="urlLink2" class="logo"></a> -->
        <!-- <a href="https://drome.co.in/"><img src="../../../assets/images/beta_logo.png" class="logo"></a> -->
        <p>Drome Services Pvt. Ltd.</p>

    </div>
    <div class="f-right">
        <ul>
            <li><a href="https://drome.co.in/contact-us" target="_blank">Contact Us</a></li>
            <li><a href="https://drome.co.in/privacy-policy-2" target="_blank">Privacy Policy</a></li>
            <li><a href="https://drome.co.in/terms-and-conditions" target="_blank">Terms & Conditions</a></li>
        </ul>
    </div>
</div>