<header>
    <div class="top-header">
        <div class="container-fluid ">
            <div class="top-menu">
                <div class="lft-logo">
                    <a href="https://drome.co.in" target="_blank"><img src="../../assets/images/beta_logo.png" alt="" style="width:auto;height:60px" class="logo"></a>
                </div>
            </div>
        </div>
    </div>
</header>
<section style="padding: 0 20px;">
    <router-outlet></router-outlet>
</section>
<div class="footer">
    <div class="f-left">
        <!-- <a href="https://drome.co.in/"><img [src]="urlLink2" class="logo"></a> -->
        <!-- <a href="https://drome.co.in/"><img src="../../../assets/images/beta_logo.png" class="logo"></a> -->
        <p>Drome Services Pvt. Ltd.</p>

    </div>
    <div class="f-right">
        <ul>
            <li><a href="https://drome.co.in/contact-us" target="_blank">Contact Us</a></li>
            <li><a href="https://drome.co.in/privacy-policy-2" target="_blank">Privacy Policy</a></li>
            <li><a href="https://drome.co.in/terms-and-conditions" target="_blank">Terms & Conditions</a></li>
        </ul>
    </div>
</div>