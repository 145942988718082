import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DataEncryptionDcryption } from 'src/app/core/services/dataEncryptionDcryption.service';

@Injectable({
  providedIn: 'root'
})
export class MessagedoctorsService {
  constructor(
    private http: HttpClient,
    private dataEncryptionDcryption: DataEncryptionDcryption,

  ) {

  }
  apiUrl = environment.dromeApiUrl;
  //patient message
    getMessagingServiceForPatients(nPatientUserId,FromDt,ToDt,nDoctorUserId): Observable<any> {
        return this.http.get<any>(`${this.apiUrl}/MessagingService/GetMessagingServiceForPatients/${nPatientUserId}/${FromDt}/${ToDt}/${nDoctorUserId}`);
    }
    
    UnReadMessageCountForPatients(nPatientUserId): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/MessagingService/UnReadMessageCountForPatients/${nPatientUserId}`);
    }
   
    GetUnReadMessageForPatients(nPatientUserId:number): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/MessagingService/GetUnReadMessageForPatients/${nPatientUserId}`);
    }
 
 //doctor message
    
    GetMessagingServiceForDoctors(nDoctorUserId,FromDt,ToDt,nPatientUserId): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/MessagingService/GetMessagingServiceForDoctors/${nDoctorUserId}/${FromDt}/${ToDt}/${nPatientUserId}`);
    }
    GetUnReadMessageForDoctors(nDoctorUserId:number): Observable<any> {
      return this.http.get<any>(`${this.apiUrl}/MessagingService/GetUnReadMessageForDoctors/${nDoctorUserId}`);
  }
    UnReadMessageCountForDoctors(nDoctorUserId:number): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/MessagingService/UnReadMessageCountForDoctors/${nDoctorUserId}`);
    }
   
  postMesseage(messagingService: object, file: File,fileSize, formType): Observable<any> {

    const formData = new FormData();


    // Store form name as "file" with file data
    formData.append("imageFile", file);
    formData.append("fileSize", fileSize);
    formData.append("MessagingService", JSON.stringify(messagingService));
    if (formType == "submit") {
        return this.http.post(`${this.apiUrl}/MessagingService`, formData);
    }
    else {
        return this.http.put(`${this.apiUrl}/MessagingService`, formData);
    }
}
  readMesseage(messagingService: object): Observable<any> {
    return this.http.put(`${this.apiUrl}/MessagingService/MessagingService_Read`, messagingService);
}

  GetMessagingServices(nUserId,FromDt,ToDt,vGeneric): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/MessagingService/GetMessagingServices/${this.dataEncryptionDcryption.encryptData(nUserId.toString())}/${FromDt}/${ToDt}/${vGeneric}`);
  }
  UnReadMessageCount(nUserId): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/MessagingService/UnReadMessageCount/${this.dataEncryptionDcryption.encryptData(nUserId.toString())}`);
  }

  GetUnReadMessages(nUserId): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/MessagingService/GetUnReadMessages/${this.dataEncryptionDcryption.encryptData(nUserId.toString())}`);
  }
  GetMembersForMessaging(nUserId,vGeneric): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/MessagingService/GetMembersForMessaging/${this.dataEncryptionDcryption.encryptData(nUserId.toString())}/${vGeneric}`);
  }
}
