import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PatientDashboardComponent } from './patient-dashboard/patient-dashboard.component';
import { IsSignedInGuard } from './core/guards/signIn.guard';
import { AuthGuard } from './core/guards/auth.guard';
import { AccountComponent } from './account/account.component';
import { PageComponent } from './page/page.component';













const routes: Routes = [
   { path: '', redirectTo: '/login', pathMatch: "full" },
  // {
  //   path: "", component: LoginAndRegisterComponent, children: [
  //     { path: "login", component: LoginFormComponent, canActivate: [IsSignedInGuard] },
  //     // { path: "register", component: RegisterFromComponent, canActivate: [IsSignedInGuard] },
  //     { path: "memberegister", component: PatientSignUpComponent, },
  //     { path: "doctorregister", component: DoctorSignUpComponent, },
  //   ]
  // },
  { path: 'login',component:AccountComponent, canActivate: [IsSignedInGuard], loadChildren: () => import('./account/account.module').then(m => m.AccountModule)  },
  { path: 'auth',component:AccountComponent, canActivate: [IsSignedInGuard], loadChildren: () => import('./account/account.module').then(m => m.AccountModule)  },
  { path: 'dashboard',canActivate: [AuthGuard], component: PatientDashboardComponent, loadChildren: () => import('./patient-dashboard/patient-dashboard.module').then(m => m.PatientDashboardModule)  },
  { path: 'page', component: PageComponent, loadChildren: () => import('./outer-pages/outerpages.module').then(m => m.OuterPagesModule)  },

  
 


  //{ path: '**', component: PageNotFoundComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabled',
      scrollPositionRestoration: 'enabled'
    })
  ],

  exports: [RouterModule]
})
export class AppRoutingModule { }