import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { StorageService } from '../services/storage.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {
    constructor(
        private storageService: StorageService,
        private router: Router,
    ) { }
    canActivate(route: ActivatedRouteSnapshot,state: RouterStateSnapshot): boolean {
        const url = state.url;

        // Extract the segments from the URL
        
    
        
        if (this.storageService.isUserLogedIn) {
            // Dynamically check if the URL contains 'pt' and 'abhacarddetails'
            return true;
        }
        else {
            this.router.navigate(['/login']);
            return false;
        }
    }
}
