import { Component, OnInit } from '@angular/core';
import { DisableRightClickService } from './disable-right-click.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  constructor(private rightClickDisable: DisableRightClickService){} 
  loader = false;
  ngOnInit(): void {
    // this.rightClickDisable.disableRightClick();
   
  }

  onActivate(event){
    window.scrollTo(0, 0);
  }
}
