import { Directive, Input, ElementRef, AfterContentInit, } from '@angular/core';

@Directive({
  selector: '[appAutofocus]'
})
export class AutofocusDirective implements AfterContentInit {
  constructor(private readonly elementRef: ElementRef) { }

  ngAfterContentInit() {
    this.elementRef.nativeElement.focus();
  }
}


@Directive({
  selector: '[countryAutofocus]'
})
export class CountryAutofocus {
  @Input() appAutofocus: boolean;
  private el: any;
  constructor(
    public elementRef: ElementRef,
  ) {
    this.el = this.elementRef.nativeElement;

  }
  ngOnInit() {
    this.el.focus();
  }

}