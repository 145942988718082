import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
import * as CryptoJS from 'crypto-js';
import { DataEncryptionDcryption } from 'src/app/core/services/dataEncryptionDcryption.service';
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private http: HttpClient,
    private dataEncryptionDcryption: DataEncryptionDcryption,

  ) {}
  apiUrl = environment.dromeApiUrl;

  Createuser(user: object): Observable<any> {
    return this.http.post(`${this.apiUrl}/VideoCall/Createuser`, user);
}

Createroom(user: object): Observable<any> {
  return this.http.post(`${this.apiUrl}/VideoCall/Createroom`, user);
}

Getusers(): Observable<any> {
    return this.http.get(`${this.apiUrl}/VideoCall/Getusers`);
}

Getuser(user_id): Observable<any> {
    return this.http.get(`${this.apiUrl}/VideoCall/Getuser/${user_id}`);
}

Updateuser(user_id, user: object): Observable<any> {
    return this.http.put(`${this.apiUrl}/VideoCall/Updateuser/${user_id}`, user);
}

// Addparticipanttoaroom/{room_id}
Addparticipanttoaroom(room_id, user: object): Observable<any> {
  return this.http.post(`${this.apiUrl}/VideoCall/Addparticipanttoaroom/${room_id}`, user);
}
EncounterDetails_VideoCall_Update(data): Observable<any> {
  return this.http.put(`${this.apiUrl}/VideoCall/EncounterDetails_VideoCall_Update`, data);
}
DeleteRoomParticipant(roomId,userId): Observable<any> {
  return this.http.delete(`${this.apiUrl}/VideoCall/DeleteRoomParticipant/${roomId}/${userId}`,);
}



  registerUser(user: object): Observable<any> {
    return this.http.post(`${this.apiUrl}/UserMaster`, user);
  }
  PostCreateUserDoctors( postCreateUserDoctors: object,file: File,fileSize): Observable<any> {
    const formData = new FormData();
    // Store form name as "file" with file data
    formData.append('imageFile', file);
    formData.append('fileSize', fileSize);
    formData.append('UserMaster', JSON.stringify(postCreateUserDoctors));
    return this.http.post(
      `${this.apiUrl}/UserMaster/PostCreateUserDoctors`,
      formData
    );
  }

  sendOtp(userMobileNo: string, userEmail: string = ''): Observable<any> {
    if (userMobileNo) {
      return this.http.get(
        `${this.apiUrl}/Utility/SendOtpToMobleToChangeMobileNo/${userMobileNo}`
      );
    } else {
      return this.http.get(
        `${this.apiUrl}/Utility/SendOtpToEmailToChangeEmailId/${userEmail}`
      );
    }
  }
  sendOtp2(userMobileNo: string, userEmail: string = '',vPageName,nUserId,btMobile): Observable<any> {
    if (userMobileNo) {
      return this.http.get(
        `${this.apiUrl}/Utility/SendOtpToMobleToChangeMobileNo/${this.dataEncryptionDcryption.encryptData(userMobileNo.toString())}/${vPageName}/${nUserId!=null?this.dataEncryptionDcryption.encryptData(nUserId.toString()):null}/${btMobile}`
      );
    } else {
      return this.http.get(
        `${this.apiUrl}/Utility/SendOtpToEmailToChangeEmailId/${this.dataEncryptionDcryption.encryptData(userEmail)}/${vPageName}/${nUserId!=null?this.dataEncryptionDcryption.encryptData(nUserId.toString()):null}/${btMobile}`
      );
    }
  }
  ValidateOTP(mobileNo: string,vPageName,nUserId): Observable<any> {
    return this.http.get(`${this.apiUrl}/Utility/Validate/${mobileNo}/${vPageName}/${nUserId}`)
} 
ValidateMobileNumber_DeleteVerifiedOTPs(nOTP): Observable<any> {
    return this.http.get(`${this.apiUrl}/Utility/ValidateMobileNumber_DeleteVerifiedOTPs/${nOTP}`)
} 
  verifiedUserEmailByToken(user: object): Observable<any> {
    return this.http.put(
      `${this.apiUrl}/UserMaster/UserMasterVerifyEmailId_Update`,
      user
    );
  }
  //Deprecated
  loginByUNandPw(
    nRoleId: number,
    vMobileNoOrEmailId: string,
    vPassword: string
  ): Observable<any> {
    return this.http.get<any>(
      `${this.apiUrl}/UserMaster/GetUserDetailsUsingUNandPW/${nRoleId}/${vMobileNoOrEmailId}/${vPassword}`
    );
  }
  loginByvUserNameOrMemberCode(
    nRoleId: number,
    vUserNameOrMemberCode: string,
    vPassword: string,
    vDeviceId
  ): Observable<any> {
    return this.http.get<any>(
      `${this.apiUrl}/UserMaster/GetUserDetailsUsingUNandPWUsingUserName/${vUserNameOrMemberCode}/${vPassword}/${vDeviceId}`
    );
  }
  loginByvUserNameOrMemberCodePost(data): Observable<any> {
    return this.http.post<any>(
      `${this.apiUrl}/UserMaster/GetUserDetailsUsingUNandPWUsingUserNamePost`,data
    );
  } 
  loginByvUserNameOrMemberCodePost2Open(data): Observable<any> {
    return this.http.post<any>(
      `${this.apiUrl}/Open/GetUserDetailsUsingUNandPWUsingUserNamePost`,data
    );
  }
  getUserDetailsByMobOrEmail(
    nRoleId: number,
    vMobileNoOrEmailId: string
  ): Observable<any> {
    return this.http.get<any>(
      `${this.apiUrl}/UserMaster/GetUserDetails/${nRoleId}/${vMobileNoOrEmailId}`
    );
  }
  // loginToOtp(nRoleId: number, vMobileNoOrEmailId: string, isOTPOnMobile: boolean, isOTPOnEmail: boolean): Observable<any> {
  //     return this.http.get(`${this.apiUrl}/Utility/SendOtpToMobileOrEmail/${nRoleId}/${vMobileNoOrEmailId}/${isOTPOnMobile}/${isOTPOnEmail}`);
  // }
  loginToOtp(vMobileNoOrEmailId: string,isOTPOnMobile: boolean,isOTPOnEmail: boolean,vPageName,nUserId): Observable<any> {
    return this.http.get(`${this.apiUrl}/Utility/SendOtpToMobileOrEmailByUNorMC/${vMobileNoOrEmailId}/${isOTPOnMobile}/${isOTPOnEmail}/${vPageName}/${nUserId}`);
     
  }
  SendOtpToMobileOrEmailByUNorMCFP(
    vMobileNoOrEmailId: string,
    isOTPOnMobile: boolean,
    isOTPOnEmail: boolean,
    vPageName,
    nUserId

  ): Observable<any> {
    return this.http.get(
      `${this.apiUrl}/Utility/SendOtpToMobileOrEmailByUNorMCFP/${vMobileNoOrEmailId}/${isOTPOnMobile}/${isOTPOnEmail}/${vPageName}/${nUserId}`
    );
  }
  // userMasterForgetPassword(user: object): Observable<any> {
  //     return this.http.put(`${this.apiUrl}/UserMaster/UserMasterForgetPassword`, user);
  // }
  userMasterForgetPassword(user: object): Observable<any> {
    return this.http.put(
      `${this.apiUrl}/UserMaster/UserMasterForgetPasswordUsingUserIdorMemberCode`,
      user
    );
  }
  getUserDetailsByUserId(nUserId: number, vDeviceId): Observable<any> {
    return this.http.get<any>(
      `${this.apiUrl}/UserMaster/GetUserDetailsByUserId/${nUserId}/${vDeviceId}`
    );
  }
  //     GetReferUsers     //
  GetReferUsers(vGeneric: any): Observable<any> {
    return this.http.get<any>(
      `${this.apiUrl}/UserMaster/GetReferUsers/${vGeneric}`
    );
  } 
  GetReferUsers2(vGeneric: any): Observable<any> {
    return this.http.get<any>(
      `${this.apiUrl}/Open/GetReferUsers/${vGeneric}`
    );
  }
  //     GetReferUsers    //

  GetMemberStatus(vMemberCode: any): Observable<any> {
    return this.http.get<any>(
      `${this.apiUrl}/UserMaster/GetMemberStatus/${vMemberCode}`
    );
  }
  GetMemberStatus2(vMemberCode: any): Observable<any> {
    return this.http.get<any>(
      `${this.apiUrl}/Open/GetMemberStatus/${vMemberCode}`
    );
  }
  GetMemberDetailsByMobileNo(vMobileNo,vEmailId): Observable<any> {
    return this.http.get<any>(
      `${this.apiUrl}/Open/GetMemberDetailsByMobileNo/${vMobileNo}/${vEmailId}`
    );
  }
 
}
