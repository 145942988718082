import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
import * as CryptoJS from 'crypto-js';
import { DataEncryptionDcryption } from './dataEncryptionDcryption.service';
@Injectable({
    providedIn: 'root'
})
export class UtilityService {

    constructor(
        private http: HttpClient,
        private dataEncryptionDcryption: DataEncryptionDcryption,

    ) {

    }
    apiUrl = environment.dromeApiUrl;
    getCurrentDate(): Observable<any> {
        return this.http.get(`${this.apiUrl}/Utility/GetCurrentDBDate`);

    }
    getCurrentDBDateDDMMMYYYY(): Observable<any> {
        return this.http.get(`${this.apiUrl}/Utility/GetCurrentDBDateDDMMMYYYY`);

    }
    getUserDob(nUserId: number): Observable<any> {
        return this.http.get(`${this.apiUrl}/PatientMaster/GetPatientDOB/${nUserId}`);

    }
    updateUserDob(user: any): Observable<any> {
        return this.http.put(`${this.apiUrl}/PatientMaster/PatientDOB_Update`, user);

    }
    getCurrentDBTime(): Observable<any> {
        return this.http.get(`${this.apiUrl}/Utility/GetCurrentDBTime`);
    }
    getDashBoardLinks(nUserId: number): Observable<any> {
        return this.http.get(`${this.apiUrl}/UserMaster/GetDashBoardLinks/${nUserId}`);
    } 
    logout(data): Observable<any> {
        return this.http.post(`${this.apiUrl}/UserMaster/logout`,data);
    }
    GetCountSecondaryUsers(primaryPatientUserId: number): Observable<any[]> {
        return this.http.get<any[]>(`${this.apiUrl}/PatientSuperUserDetails/GetCountSecondaryUsers/${this.dataEncryptionDcryption.encryptData(primaryPatientUserId.toString())}`);
    }
    getSecondaryPatientSuperUserDetailsWithPrimary(primaryPatientUserId: number): Observable<any[]> {
        return this.http.get<any[]>(`${this.apiUrl}/PatientSuperUserDetails/GetSecondaryPatientSuperUserDetailsWithPrimary/${this.dataEncryptionDcryption.encryptData(primaryPatientUserId.toString())}`);
    }

  
    


}
