import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DataEncryptionDcryption } from 'src/app/core/services/dataEncryptionDcryption.service';
import { OrganizationDetailsList } from 'src/app/models/organization-details';

@Injectable({
  providedIn: 'root'
})
export class OrganizationDetailsService {

  
  constructor(
    private http: HttpClient,
    private dataEncryptionDcryption: DataEncryptionDcryption,

  ) {

  }
  apiUrl = environment.dromeApiUrl;

  getOrganizationDetails(): Observable<OrganizationDetailsList[]> {
    return this.http.get<OrganizationDetailsList[]>(`${this.apiUrl}/OrganizationDetails`);
  }
  getOrganizationDetailsForheader(nLoggedInUserId ): Observable<OrganizationDetailsList[]> {
    return this.http.get<OrganizationDetailsList[]>(`${this.apiUrl}/OrganizationDetails/OrganizationDetails_SelectAllCityWise/${this.dataEncryptionDcryption.encryptData(nLoggedInUserId.toString())}`);
  }
  // saveState(stateMaster: object , formType: String): Observable<any> {
  //     if (formType == "Submit") {
  //         return this.http.post(`${this.apiUrl}/StateMaster`, stateMaster);
  //     }

  //     else {
  //         return this.http.put(`${this.apiUrl}/StateMaster`, stateMaster);

  //     }
  // }
  updateOrganizationDetails(OrganizationDetails: object, file1: File,file2: File, FormType :string): Observable<any> {
    if(FormType == "Submit"){
      const formDataAdd = new FormData();
      formDataAdd.append("imageFile1", file1);
      formDataAdd.append("imageFile2", file2);
      formDataAdd.append("OrganizationDetails", JSON.stringify(OrganizationDetails));
      return this.http.post(`${this.apiUrl}/OrganizationDetails`, formDataAdd);
      
    }
    
    else{
      const formData = new FormData();
     // Store form name as "file" with file data
    formData.append("imageFile1", file1);
    formData.append("imageFile2", file2);
    formData.append("OrganizationDetails", JSON.stringify(OrganizationDetails));
    return this.http.put(`${this.apiUrl}/OrganizationDetails`, formData);
    }
  }
  // getActiveStateList(): Observable<StateMaster[]> {
  //     return this.http.get<StateMaster[]>(`${this.apiUrl}/StateMaster/GetAllActiveStates`);

  // }

  fileUpload(file: File, fileSize, userId): Observable<any> {
    const formData = new FormData();

    // Store form name as "file" with file data
    let fileType = file.name.split('.')[1]
    formData.append("imageFile", file);
    formData.append("userId", userId);
    formData.append("fileSize", fileSize);
    return this.http.put(`${this.apiUrl}/DoctorMaster/DoctorMasterPhotoDetails_Update`, formData)
  }
  // getProfileDetailsByUserId(userId): Observable<any> {
  //   return this.http.get(`${this.apiUrl}/UserMaster/GetProfileDetails/${userId}`)
  // }
}
