import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
import { DataEncryptionDcryption } from 'src/app/core/services/dataEncryptionDcryption.service';
import { PatientViewAndUpdate } from 'src/app/models/patient-view-and-update';

@Injectable({
  providedIn: 'root'
})
export class ViewUpdateProfileService {

  constructor(
    private http: HttpClient,
    private dataEncryptionDcryption: DataEncryptionDcryption

  ) {

  }
  apiUrl = environment.dromeApiUrl;

  getPatientViewAndUpdate(): Observable<PatientViewAndUpdate[]> {
    return this.http.get<PatientViewAndUpdate[]>(`${this.apiUrl}/PatientMaster`);
  }
  // saveState(stateMaster: object , formType: String): Observable<any> {
  //     if (formType == "Submit") {

  //         return this.http.post(`${this.apiUrl}/StateMaster`, stateMaster);
  //     }

  //     else {
  //         return this.http.put(`${this.apiUrl}/StateMaster`, stateMaster);

  //     }
  // }
  updatePatientViewAndUpdate(patientViewAndUpdate: object): Observable<any> {
    return this.http.put(`${this.apiUrl}/PatientMaster`,{ data: this.dataEncryptionDcryption.encryptData(JSON.stringify(patientViewAndUpdate))});

  }
  // getActiveStateList(): Observable<StateMaster[]> {
  //     return this.http.get<StateMaster[]>(`${this.apiUrl}/StateMaster/GetAllActiveStates`);

  // }

  fileUpload(file: File, fileSize, userId, nRoleId): Observable<any> {
    const formData = new FormData();

    // Store form name as "file" with file data
    // let fileType = file.name.split('.')[1]
    formData.append("imageFile", file);
    formData.append("userId", userId);
    formData.append("roleId", nRoleId);
    formData.append("fileSize", fileSize);
    return this.http.put(`${this.apiUrl}/UserMaster/ProfilePhotoDetails_Update`, formData)
  }
  // this.dataEncryptionDcryption.encryptData()
  getProfileDetailsByUserId(userId): Observable<any> {
    // Create headers with X-User-Id
    const headers = new HttpHeaders({
      'X-User-Id': this.dataEncryptionDcryption.encryptData(userId.toString())
    });
    return this.http.get(`${this.apiUrl}/PatientMaster/GetProfileDetails/${this.dataEncryptionDcryption.encryptData(userId.toString())}`,{headers});
  }


}
