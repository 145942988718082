import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';





import { FormsModule, ReactiveFormsModule } from '@angular/forms';





import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';

import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { BsModalService } from 'ngx-bootstrap/modal';
import { ModalModule } from 'ngx-bootstrap/modal';
import { DataTablesModule } from 'angular-datatables';
import { NotificationService } from './core/services/notification.service';
import { ToastrModule } from 'ngx-toastr';

import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { StorageService } from './core/services/storage.service';
// import { FacebookLoginProvider, SocialLoginModule, SocialAuthServiceConfig } from 'angularx-social-login';
import { AuthGuard } from './core/guards/auth.guard';
import { IsSignedInGuard } from './core/guards/signIn.guard';

//import { NgSelectModule } from '@ng-select/ng-select';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { WebcamModule } from 'ngx-webcam';

import { DragDropModule } from '@angular/cdk/drag-drop';


import { AdminStorageService } from './core/services/adminStorage.service';



import { MatStepperModule } from '@angular/material/stepper';
import { HttpconfigInterceptor } from './core/interceptors/httpconfig.interceptor';

//import { //NgxWatermarkModule } from 'ngx-watermark';
import { IvyCarouselModule } from 'angular-responsive-carousel';

import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { ImageCropperModule } from 'ngx-image-cropper';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { FlatpickrModule } from 'angularx-flatpickr/flatpickr.module';
import { ConnectionServiceModule } from 'ng-connection-service';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import {ClipboardModule} from '@angular/cdk/clipboard';

import { MatTabsModule } from '@angular/material/tabs';
import { NgOtpInputModule } from 'ng-otp-input';


import { HttpHeadersInterceptor } from './core/services/http-headers.interceptor';
import { PageComponent } from './page/page.component';
import { PatientDashboardModule } from './patient-dashboard/patient-dashboard.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';





@NgModule({
  declarations: [
    AppComponent,
    PageComponent,

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatSidenavModule,
    MatToolbarModule,
    MatMenuModule,
    MatIconModule,
    MatDividerModule,
    MatListModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    HttpClientModule,
    ModalModule.forRoot(),
    DataTablesModule,
    ToastrModule.forRoot(),
    MatDatepickerModule,
    MatNativeDateModule,
    // SocialLoginModule,
    //NgSelectModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatRadioModule,
    WebcamModule,
    MatStepperModule,
    NgxMaterialTimepickerModule,
    //NgxWatermarkModule,
    IvyCarouselModule,
    ImageCropperModule,
    //FlatpickrModule.forRoot(),
    CalendarModule.forRoot({ provide: DateAdapter, useFactory: adapterFactory }),
    ConnectionServiceModule,
    MatAutocompleteModule,
    ClipboardModule,
    MatTabsModule,
    NgOtpInputModule,
    DragDropModule,
    PatientDashboardModule
    
],
  exports: [
    
  ],

  providers: [
    BsModalService,
    NotificationService,
    StorageService,
    AdminStorageService,
    AuthGuard,
    IsSignedInGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpconfigInterceptor,
      multi: true
    },
    { provide: HTTP_INTERCEPTORS, useClass: HttpHeadersInterceptor, multi: true },
    // { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },

    // { provide: MAT_DATE_FORMATS, useValue: APP_FORMATS },
    // {
    //   provide: 'SocialAuthServiceConfig',
    //   useValue: {
    //     autoLogin: false,
    //     providers: [
    //       {
    //         id: FacebookLoginProvider.PROVIDER_ID,
    //         provider: new FacebookLoginProvider(
    //           '3081126458800044'
    //         )
    //       }
    //     ]
    //   } as SocialAuthServiceConfig,
    // }
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],

})
export class AppModule {

}