import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PatientDashboardRoutingModule } from './patient-dashboard-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTabsModule } from '@angular/material/tabs';
import { RouterModule } from '@angular/router';
import { DataTablesModule } from 'angular-datatables';
import { IvyCarouselModule } from 'angular-responsive-carousel';
import { NgApexchartsModule } from 'ng-apexcharts';
import { ImageCropperModule } from 'ngx-image-cropper';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { WebcamModule } from 'ngx-webcam';
import { PageFilterPipe } from '../core/pipe/page-filter';
import { FilterPipe } from '../core/shared/filter.pipe';
import { PatientDashboardComponent } from './patient-dashboard.component';
import { SharedModule } from '../shared/shared.module';
import { MatExpansionModule } from '@angular/material/expansion';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';



@NgModule({
  declarations: [
    PatientDashboardComponent,
    PageFilterPipe,
    FilterPipe
  ],
  imports: [
    CommonModule,
    PatientDashboardRoutingModule,
    //BrowserAnimationsModule,
    MatTabsModule,
    MatExpansionModule,
    FormsModule,
    ReactiveFormsModule,
    MatDividerModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
    RouterModule,
    MatAutocompleteModule,
    NgApexchartsModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatRadioModule,
    MatIconModule,
    DataTablesModule,
    WebcamModule,
    MatSelectModule,
    MatStepperModule,
    NgxMaterialTimepickerModule,
    IvyCarouselModule,
    ImageCropperModule,
    SharedModule
  ],
  exports: [
  ]
})
export class PatientDashboardModule { }
