import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {

 
  constructor(public router: Router) {
  
  }
pageName
 ngOnInit(): void {
  let url = window.location.href
  this.pageName= url.substring(url.lastIndexOf('/') + 1);
  console.log('pageName',this.pageName)
   this.router.events.subscribe((evt) => {
     if (!(evt instanceof NavigationEnd)) {
       return;
     }
     window.scrollTo(0, 0);
   });
 }

 redairect(){
   window.open("https://drome.co.in", "_blank");
  }
  LoginRedairect(){
   this.router.navigate(['/login']);
  } 
  SignupRedairect(){
   this.router.navigate(['/auth/signup/memberegister']);
  }

}
