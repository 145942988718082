
<div class="loader" *ngIf="loader">
  <div class="loading">
      <img src="../../../../assets/loaderlogo/watermark_logo.gif" alt="">
  </div>
</div>
<div class="floating-box">
    <button class="close_modal" (click)="closemodal()">
      <span>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/></svg>
      </span>
    </button>
    <div class="details" *ngIf="addmemberbtn">
      <h1 *ngIf="data?.memberDetail">{{data?.memberDetail}}</h1>
      <div class="btn_box_add_mem">
        <button *ngIf="!Add1User" class="updateuser" (click)="openAddmember(addmemberemplateModel,'Add 1 Member')" >
          <span> {{text1}} 1 Member</span>
          
        </button>
        <span *ngIf="!Add1User" class="edituser" (click)="tooglebtn1('mem1can','')" >
          <svg style="width: 12px;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/></svg>
        </span>
      </div>
      <div class="btn_box_add_mem">
        <button *ngIf="Add1User" class="updateuser" style="cursor: none;">
          <span>{{Add1User}}</span>
          
        </button>
        <span *ngIf="Add1User" class="edituser" style="cursor: pointer;" (click)="tooglebtn1('mem1edit',Add1User)" >
          <svg style="width: 15px;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160L0 416c0 53 43 96 96 96l256 0c53 0 96-43 96-96l0-96c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 96c0 17.7-14.3 32-32 32L96 448c-17.7 0-32-14.3-32-32l0-256c0-17.7 14.3-32 32-32l96 0c17.7 0 32-14.3 32-32s-14.3-32-32-32L96 64z"/></svg>
        </span>
      </div>
      <div class="btn_box_add_mem">
        <button *ngIf="!Add2User" class="updateuser" (click)="openAddmember(addmemberemplateModel,'Add 2 Member')">
          <span>{{text2}} 2 Member</span>
        </button> 
        <span *ngIf="!Add2User" class="edituser" (click)="tooglebtn2('mem2can','')" >
          <svg style="width: 15px;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/></svg>
        </span>
      </div>
      <div class="btn_box_add_mem">
        <button style="cursor: none;" *ngIf="Add2User" class="updateuser">
          <span>{{Add2User}}</span>
        </button>
        <span *ngIf="Add2User" class="edituser" style="cursor: pointer;" (click)="tooglebtn2('mem2edit',Add2User)">
          <svg style="width: 12px;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160L0 416c0 53 43 96 96 96l256 0c53 0 96-43 96-96l0-96c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 96c0 17.7-14.3 32-32 32L96 448c-17.7 0-32-14.3-32-32l0-256c0-17.7 14.3-32 32-32l96 0c17.7 0 32-14.3 32-32s-14.3-32-32-32L96 64z"/></svg>
        </span>
      </div>
    </div>
    <iframe width="100%"  height="100% " [src]="safeUrl"
    allowfullscreen mozallowfullscreen webkitallowfullscreen 
    allow="camera *;microphone *; display-capture *; picture-in-picture *; autoplay *; fullscreen *"
    sandbox ="allow-scripts allow-downloads allow-same-origin allow-forms" [ngClass]="{'nonhead':!data?.memberDetail}" style="padding-top: 10px;"></iframe>
    <div class="resizer top-left"></div>
    <div class="resizer top-right"></div>
    <div class="resizer bottom-left"></div>
    <div class="resizer bottom-right"></div>
    <div class="resizer left"></div>
    <div class="resizer right"></div>
    <div class="resizer top"></div>
    <div class="resizer bottom"></div>
  </div>
  
  <ng-template #addmemberemplateModel>
    <div class="country-edit-model">
      <div class="model-header">
        <!-- <button type="button" class="close-btn pull-right" aria-label="Close"
                  (click)="modalRef.hide()">
                  <mat-icon>close</mat-icon>
              </button> -->
        <h1 mat-dialog-title>{{AddMembers}}</h1>
      </div>
      <mat-divider></mat-divider>
    </div>
    <div mat-dialog-content style="padding: 0 10px;">
      <form [formGroup]="addmemberForm">
        <div class="record-type">
          <div class="input-group  custom-select mb-3" style="width: 100%;">
            <label for="">Select Member<span>*</span></label>
            <input (click)="clickPatientbox($event)" type="text" placeholder="Select Patient"
              formControlName="nPatientUserId" readonly
              [ngClass]="{'is-invalid' : addmemberFormFormControls.nPatientUserId.invalid && (addmemberFormFormControls.nPatientUserId.dirty || addmemberFormFormControls.nPatientUserId.touched)}">
            <div class="invalid-tooltip"
              *ngIf="addmemberFormFormControls.nPatientUserId.invalid && (addmemberFormFormControls.nPatientUserId.dirty || addmemberFormFormControls.nPatientUserId.touched)">
              Please Select Patient
            </div>
            <div *ngIf="selectPatientList" class="input-feild select-list" [@slideInOutAnimation]
              (click)="showSelectPatientList($event)">
              <div (keyup)="onPatientKey($event)" class="search-input"><input #searchInput (focusout)="onFocusOut($event)"
                  appAutofocus type="text" placeholder="Enter 3 letters to search Patient"><span class="search-icon"><svg
                    xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search"
                    viewBox="0 0 16 16">
                    <path
                      d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                  </svg></span>
                <div class="close" (click)="closePatientSearch()">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x"
                    viewBox="0 0 16 16">
                    <path
                      d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                  </svg>
                </div>
              </div>
              <ul>
                <li *ngIf="patientDetailDropDown.length==0 && noMatchFound">No Match Found
                </li>
                <li (click)="selectPatientId(item,$event)" *ngFor="let item of patientDetailDropDown"
                  [value]="item.nUserId">
                  {{item.PatientDetail}}</li>
              </ul>
            </div>
          </div>
        </div>
      </form>
    </div>
    <mat-divider></mat-divider>
    <div class="confirm-btn confirm-btn_2 mt-3">
      <button type="button" (click)="modalRef.hide()">Cancel</button>
      <button (click)="EncounterDetails_VideoCall_Update()" class="confirmBtn" [disabled]="addmemberForm.invalid" type="button">Submit</button>
    </div>
  </ng-template>